import * as React from "react";
import {graphql, PageProps} from "gatsby";

import Layout from "../../../../components/layout";
import Hero from '../../../../components/hero';
import TrustpilotCarousel from "../../../../components/trustpilot/trustpilot-carousel";
import LocationsFilter from "../../../../components/locations-filter";
import TemplateHead from "../../../../components/template-head";

type DataProps = {
  allLocationsByTypeHubCity: any,
  allChurchesByCity: any,
  allCemeteriesByCity: any,
  allCrematoriumsByCity: any,
  allBurialGroundsByCity: any,
  slug: any,
  currentType: any,
  currentHub: any,
  currentCity: any
}

export default function Locations({
                                    data: {
                                      currentType,
                                      currentHub,
                                      currentCity,
                                      allChurchesByCity,
                                      allCemeteriesByCity,
                                      allCrematoriumsByCity,
                                      allBurialGroundsByCity,
                                      allLocationsByTypeHubCity
                                    }
                                  }: PageProps<DataProps>) {

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--locations pl-4 pr-4"
        bgImageClassName="hero-image hero-image--blog"
        contentClassName="hero-content hero-content--blog"
        altImages="Fenix Funeral Directors"
        imageGlow={"/images/glow.png"}
        breadcrumb={[{name: 'Fenix Home', slug: '/'}, {name: 'Locations', slug: '/locations/'}, {
          name: currentHub.title,
          slug: '/locations/' + currentType.slug + '/' + currentHub.urlPart + '/'
        }, {
          name: currentType.title + " in " + currentCity.title,
          slug: '/locations/' + currentType.slug + '/' + currentHub.urlPart + '/' + currentCity.cityUrl + '/'
        }]}
      >
      </Hero>
      <LocationsFilter
        locationsData={allLocationsByTypeHubCity.nodes}
        locationsChurchesCity={allChurchesByCity}
        locationsCemeteriesCity={allCemeteriesByCity}
        locationsCrematoriumsCity={allCrematoriumsByCity}
        locationsBurialGroundsCity={allBurialGroundsByCity}
        contextDataType={currentType}
        contextDataHub={currentHub}
        contextDataCity={currentCity}
      />
      <TrustpilotCarousel/>
    </Layout>
  )
}

export function Head({data: {allLocationsByTypeHubCity, currentType, currentHub, currentCity}}) {

  const pageTitle = '▷ ' + allLocationsByTypeHubCity.nodes.length + ' ' + currentType.title.toLowerCase() + ' for funeral in ' + currentCity.title + '  • Fenix Funeral Directors'
  const pageDescription = 'Arrange funeral in ' + currentCity.title + ' → For more information about ' + allLocationsByTypeHubCity.nodes.length + ' ' + currentType.title.toLowerCase() + ' for funeral  ✓ We help you with bookings | Fenixfuneral.co.uk'
  const pageLink = 'https://fenixfuneral.co.uk/locations/' + currentType.slug + '/' + currentHub.urlPart + '/' + currentCity.cityUrl + '/'

  return (
    <TemplateHead
      title={pageTitle}
      description={pageDescription}
      shortlink={pageLink}
      alternate={pageLink}
      canonical={pageLink}
      og={{
        url: pageLink,
        title: pageTitle,
        description: pageDescription
      }}
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
            "@type": "ListItem",
            "position": "1",
            "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"
            },
            {
            "@type": "ListItem",
            "position": "2",
            "name": "Locations","item": "https://fenixfuneral.co.uk/locations/"
            },
            {
            "@type": "ListItem",
            "position": "3",
            "name": "${currentHub.title}","item": "https://fenixfuneral.co.uk/locations/${currentType.slug}/${currentHub.urlPart}/"
            },
            {
            "@type": "ListItem",
            "position": "4",
            "name": "${currentHub.title} in ${currentCity.title}","item": "https://fenixfuneral.co.uk/locations/${currentType.slug}/${currentHub.urlPart}/${currentCity.cityUrl}/"
            }
          ]
        }`
      }
    />
  );
}

export const pageQuery = graphql`
    fragment locationData on STRAPI_LOCATIONConnection {
        nodes {
            strapi_id
            title
            slug
            slugFull
            images {
                awsThumbnail
                awsImage
            }
            type {
                slug
                title
            }
            hub {
                title
                urlPart
            }
            city {
                title
                cityUrl
            }
        }
    }
    query ($type__slug: String, $hub__urlPart: String, $city__cityUrl: String) {
        allLocationsByTypeHubCity: allStrapiLocation(
            filter: {type: {slug: {eq: $type__slug}}, hub: {urlPart: {eq:$hub__urlPart}}, city: {cityUrl: {eq: $city__cityUrl}}}
            sort: {fields: title, order: ASC}) {
            ...locationData
        }
        allChurchesByCity: allStrapiLocation(filter: {type: {slug: {eq: "churches"}}, hub: {urlPart: {eq:$hub__urlPart}}, city: {cityUrl: {eq: $city__cityUrl}}}) {
            edges {
                node {
                    strapi_id
                }
            }
        }
        allCemeteriesByCity: allStrapiLocation(filter: {type: {slug: {eq: "cemeteries"}}, hub: {urlPart: {eq:$hub__urlPart}}, city: {cityUrl: {eq: $city__cityUrl}}}) {
            edges {
                node {
                    strapi_id
                }
            }
        }
        allCrematoriumsByCity: allStrapiLocation(filter: {type: {slug: {eq: "crematoriums"}}, hub: {urlPart: {eq:$hub__urlPart}}, city: {cityUrl: {eq: $city__cityUrl}}}) {
            edges {
                node {
                    strapi_id
                }
            }
        }
        allBurialGroundsByCity: allStrapiLocation(filter: {type: {slug: {eq: "woodland-burial-grounds"}}, hub: {urlPart: {eq:$hub__urlPart}}, city: {cityUrl: {eq: $city__cityUrl}}}) {
            edges {
                node {
                    strapi_id
                }
            }
        }
        currentType: strapiLocationtype(slug: {eq: $type__slug}) {
            title
            slug
            strapi_id
        }
        currentHub: strapiHub(urlPart: {eq: $hub__urlPart}) {
            strapi_id
            title
            urlPart
        }
        currentCity: strapiCity( cityUrl: {eq: $city__cityUrl}) {
            strapi_id
            title
            isHub
            cityUrl
        }
    }
`;